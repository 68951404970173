export default {
  path: '/intervenants',
  name: 'intervenants',
  component: () => import('../components/intervenant/Layout'),
  redirect: { name: 'IntervenantList' },
  children: [
    {
      name: 'IntervenantList',
      path: '',
      component: () => import('../views/intervenant/List')
    },
    {
      name: 'IntervenantCreate',
      path: 'new',
      component: () => import('../views/intervenant/Create')
    },
    {
      name: 'IntervenantUpdate',
      path: ':id/edit',
      component: () => import('../views/intervenant/Update')
    },
    {
      name: 'IntervenantShow',
      path: ':id',
      component: () => import('../views/intervenant/Show')
    }
  ]
};
