export default {
  path: '/animations',
  name: 'animations',
  component: () => import('../components/animation/Layout'),
  redirect: { name: 'AnimationList' },
  children: [
    {
      name: 'AnimationList',
      path: '',
      component: () => import('../views/animation/List')
    },
    {
      name: 'AnimationCreate',
      path: 'new',
      component: () => import('../views/animation/Create')
    },
    {
      name: 'AnimationUpdate',
      path: ':id/edit',
      component: () => import('../views/animation/Update')
    },
    {
      name: 'AnimationShow',
      path: ':id',
      component: () => import('../views/animation/Show')
    }
  ]
};
