export default {
  path: '/articles',
  name: 'articles',
  component: () => import('../components/article/Layout'),
  redirect: { name: 'ArticleList' },
  children: [
    {
      name: 'ArticleList',
      path: '',
      component: () => import('../views/article/List')
    },
    {
      name: 'ArticleCreate',
      path: 'new',
      component: () => import('../views/article/Create')
    },
    {
      name: 'ArticleUpdate',
      path: ':id/edit',
      component: () => import('../views/article/Update')
    },
    {
      name: 'ArticleShow',
      path: ':id',
      component: () => import('../views/article/Show')
    }
  ]
};
