yarn
<template>
  <v-app id="inspire">
    <snackbar></snackbar>
    <v-navigation-drawer v-model="showMenu" app>
      <logged-user/>
      <menu-admin dense/>
    </v-navigation-drawer>
    <v-app-bar app color="indigo" dark>
      <v-app-bar-nav-icon @click.stop="showMenu = !showMenu"></v-app-bar-nav-icon>
      <v-toolbar-title>Ludinam : Administration du site</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <Breadcrumb layout-class="pl-3 py-3"/>
      <router-view v-if="token"/>
      <login v-else/>
    </v-main>
    <v-footer color="indigo" app>
      <span class="white--text">&copy; 2019</span>
    </v-footer>
  </v-app>
</template>

<style lang="scss">
@import '~vue-wysiwyg/dist/vueWysiwyg.css';

.menu-item-cog .v-list-group__header {
  padding: 0px;
}
</style>

<script>
import Breadcrumb from './components/Breadcrumb';
import Snackbar from './components/Snackbar';
import {mapFields} from "vuex-map-fields";
import Login from "./components/Login";
import LoggedUser from "./components/user/LoggedUser";
import MenuAdmin from "./components/Menu";

export default {
  components: {
    MenuAdmin,
    LoggedUser,
    Login,
    Breadcrumb,
    Snackbar
  },

  data: () => ({
    drawer: null,
    globalParams: [
      {
        'name': 'active_event',
        'text': 'édition active'
      },
      {
        'name': 'infos_pratiques',
        'text': 'Informations pratiques'
      }
      ,
      {
        'name': 'inscription_cosplay',
        'text': 'Concours cosplay'
      },
      {
        'name': 'inscription_benevoles',
        'text': 'Inscription bénévoles'
      },
      {
        'name': 'inscription_exposants',
        'text': 'Inscription exposants'
      },
      {
        'name': 'inscription_concours_createurs',
        'text': 'Inscription concours créateurs'
      }
    ]
  }),
  computed: {
    ...mapFields('userparameters', {
      token: 'token',
      showMenu: 'showMenu'
    }),
  }
};
</script>