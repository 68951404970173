<template>
  <v-list :dense="dense">
    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-home</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <router-link :to="{ name: 'Home' }">Accueil</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-cog</v-icon>
      </v-list-item-action>
      <v-list-group class="px-0 menu-item-cog">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Paramètre du site</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list>
          <v-list-item v-for="(item, i) in globalParams" :key="i">
            <v-list-item-content>
              <v-list-item-title>
                <router-link :to="{ name: item.name }">{{ item.text }}</router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list-group>

    </v-list-item>
    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-post</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <router-link :to="{ name: 'ArticleList' }">Articles</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-transition</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <router-link :to="{ name: 'AnimationList' }">Animation</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-map-marker</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <router-link :to="{ name: 'SiteList' }">Sites</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-map-marker</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <router-link :to="{ name: 'LocalisationList' }">Localisations</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-handshake</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <router-link :to="{ name: 'PartenaireList' }">Partenaires</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-account-group</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <router-link :to="{ name: 'IntervenantList' }">Intervenants</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-calendar-range</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <router-link :to="{ name: 'EvenementList' }">Dates de Ludinam</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-file</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <router-link :to="{ name: 'MediaObjectList' }">Gestionnaire de fichiers</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "MenuAdmin",
  props:["dense"],
  data: function (){
    return{
      globalParams: [
        {
          'name': 'active_event',
          'text': 'édition active'
        },
        {
          'name': 'infos_pratiques',
          'text': 'Informations pratiques'
        }
        ,
        {
          'name': 'inscription_cosplay',
          'text': 'Concours cosplay'
        },
        {
          'name': 'inscription_benevoles',
          'text': 'Inscription bénévoles'
        },
        {
          'name': 'inscription_exposants',
          'text': 'Inscription exposants'
        },
        {
          'name': 'inscription_concours_createurs',
          'text': 'Inscription concours créateurs'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>