export default {
    path: '/global-params',
    name: 'global_params',
    component: () => import('../components/globalParams/Layout'),
    children: [
        {
            name: 'active_event',
            path: 'active_event',
            component: () => import('../views/globalParams/ActiveEvent')
        },
        {
            name: 'infos_pratiques',
            path: 'infos_pratiques',
            component: () => import('../views/globalParams/InfosPratiques')
        },
        {
            name: 'inscription_cosplay',
            path: 'inscription_cosplay',
            component: () => import('../views/globalParams/ConcoursCosplay')
        },
        {
            name: 'inscription_benevoles',
            path: 'inscription_benevoles',
            component: () => import('../views/globalParams/InscriptionBenevoles')
        },
        {
            name: 'inscription_exposants',
            path: 'inscription_exposants',
            component: () => import('../views/globalParams/InscriptionExposants')
        },
        {
            name: 'inscription_concours_createurs',
            path: 'inscription_concours_createurs',
            component: () => import('../views/globalParams/InscriptionConcourCreateurs')
        },
    ]
};
  