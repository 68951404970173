<template>
    <v-container>
        <v-row xs12 align="center" justify="center">
            <v-col cols="12" sm="6">
                <v-card class="elevation-12">
                    <v-toolbar dark>
                        <v-toolbar-title>Connexion à l'interface d'administration</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>

                        <v-form align="center" @submit.prevent="login({'email':email,'password':password})"
                                id="check-login-form">
                            <v-text-field prepend-icon="mdi-mail" name="email" label="E-mail" type="text"
                                          v-model="email" :rules="emailRules" required hide-details="auto"
                                          :disabled="isLoading"/>
                            <v-text-field prepend-icon="mdi-lock" name="password" label="Password" id="password"
                                          type="password" v-model="password" required hide-details="auto"
                                          :disabled="isLoading"/>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-row xs12 align="center" justify="center" no-gutters>
                            <v-btn color="primary" type="submit" form="check-login-form"
                                   :disabled="isLoading">
                                Login
                                <v-progress-circular
                                        indeterminate
                                        color="primary"
                                        v-if="isLoading"
                                />
                            </v-btn>
                        </v-row>
                    </v-card-actions>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapFields} from 'vuex-map-fields';
    import {mapActions} from 'vuex'
    import NotificationMixin from "../mixins/NotificationMixin";

    export default {
        name: "Login",
        mixins: [NotificationMixin],
        computed: {
            ...mapFields('userparameters', {
                token: 'token',
                showMenu: 'showMenu',
                isLoading: 'isLoading',
                error: 'error'
            }),
        },
        data() {
            return {
                email: "",
                password: "",
                emailRules: [
                    v => !!v || 'E-mail requis',
                    v => /.+@.+/.test(v) || 'Votre e-mail doit être valide'
                ]
            }
        },
        methods: {
            ...mapActions({
                login: 'userparameters/login'
            })
        },
        watch: {
            error(message) {
                message && this.showError(message);
            }
        }
    }
</script>

<style scoped>

</style>