export default {
  path: '/enigmes',
  name: 'enigmes',
  component: () => import('../components/enigme/Layout'),
  redirect: { name: 'EnigmeList' },
  children: [
    {
      name: 'EnigmeList',
      path: '',
      component: () => import('../views/enigme/List')
    },
    {
      name: 'EnigmeCreate',
      path: 'new',
      component: () => import('../views/enigme/Create')
    },
    {
      name: 'EnigmeUpdate',
      path: ':id/edit',
      component: () => import('../views/enigme/Update')
    },
    {
      name: 'EnigmeShow',
      path: ':id',
      component: () => import('../views/enigme/Show')
    }
  ]
};
