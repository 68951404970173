import Vue from "vue";
import Vuex from "vuex";
import makeCrudModule from "./modules/crud";
import notifications from "./modules/notifications";
import userparameters from "./modules/userparameters";

import animationService from "../services/animation";
import animationCategorieService from "../services/animationcategorie";
import animationTypeService from "../services/animationtype";
import articleService from "../services/article";
import categoriePartenaireService from "../services/categoriepartenaire";
import enigmeService from "../services/enigme";
import evenementService from "../services/evenement";
import intervenantService from "../services/intervenant";
import intervenantTypeService from "../services/intervenanttype";
import localisationService from "../services/localisation";
import mediaobjectService from "../services/mediaobject";
import partenaireService from "../services/partenaire";
import siteService from "../services/site";
import VuexPersistence from "vuex-persist";
import globalParamsService from "../services/globalParams";

const vuexPersist = new VuexPersistence({
  key: 'Ludinam-Admin',
  storage: localStorage
})

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    notifications,
    userparameters,
    animation: makeCrudModule({
      service: animationService
    }),
    animationCategorie: makeCrudModule({
      service: animationCategorieService
    }),
    animationType: makeCrudModule({
      service: animationTypeService
    }),
    article: makeCrudModule({
      service: articleService
    }),
    categoriePartenaire: makeCrudModule({
      service: categoriePartenaireService
    }),
    enigme: makeCrudModule({
      service: enigmeService
    }),
    evenement: makeCrudModule({
      service: evenementService
    }),
    intervenant: makeCrudModule({
      service: intervenantService
    }),
    intervenantType: makeCrudModule({
      service: intervenantTypeService
    }),
    localisation: makeCrudModule({
      service: localisationService
    }),
    mediaobject: makeCrudModule({
      service: mediaobjectService
    }),
    partenaire: makeCrudModule({
      service: partenaireService
    }),
    site: makeCrudModule({
      service: siteService
    }),
    globalParam: makeCrudModule({
      service: globalParamsService
    })
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: [vuexPersist.plugin]
});

export default store;
