export default {
  path: '/animation_categories',
  name: 'animation_categories',
  component: () => import('../components/animationcategorie/Layout'),
  redirect: { name: 'AnimationCategorieList' },
  children: [
    {
      name: 'AnimationCategorieList',
      path: '',
      component: () => import('../views/animationcategorie/List')
    },
    {
      name: 'AnimationCategorieCreate',
      path: 'new',
      component: () => import('../views/animationcategorie/Create')
    },
    {
      name: 'AnimationCategorieUpdate',
      path: ':id/edit',
      component: () => import('../views/animationcategorie/Update')
    },
    {
      name: 'AnimationCategorieShow',
      path: ':id',
      component: () => import('../views/animationcategorie/Show')
    }
  ]
};
