export default {
  path: '/partenaires',
  name: 'partenaires',
  component: () => import('../components/partenaire/Layout'),
  redirect: { name: 'PartenaireList' },
  children: [
    {
      name: 'PartenaireList',
      path: '',
      component: () => import('../views/partenaire/List')
    },
    {
      name: 'PartenaireCreate',
      path: 'new',
      component: () => import('../views/partenaire/Create')
    },
    {
      name: 'PartenaireUpdate',
      path: ':id/edit',
      component: () => import('../views/partenaire/Update')
    },
    {
      name: 'PartenaireShow',
      path: ':id',
      component: () => import('../views/partenaire/Show')
    }
  ]
};
