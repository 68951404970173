import {getField, updateField} from 'vuex-map-fields';
import {ENTRYPOINT} from "../../config/entrypoint";
import SubmissionError from "../../error/SubmissionError";
import {mapFields} from 'vuex-map-fields';

const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');
const MIME_TYPE = 'application/ld+json';

export default {
    namespaced: true,
    state: {
        token: null,
        showMenu: false,
        isLoading: null,
        error: null,
        user: null
    },
    getters: {
        getField,
        token(state) {
            return state.token
        }
    },
    mutations: {
        updateField,
        updateIsLoading(state, isLoading) {
            state.isLoading = isLoading

        },
        updateToken(state, token) {
            state.token = token
            state.error = null
            if (token) {
                state.showMenu = true
            } else {
                state.showMenu = false
                state.user = null
            }
        },
        updateUser(state, user) {
            state.user = user
        },
        setError(state, error) {
            Object.assign(state, {error, isLoading: false});
        }
    },
    actions: {
        login({commit, state}, credentials) {
            commit('updateIsLoading', true)
            let options = {
                method: 'POST',
                headers: new Headers(),
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(credentials)
            }
            if (null === options.headers.get('Accept'))
                options.headers.set('Accept', MIME_TYPE);

            if (
                'undefined' !== options.body &&
                !(options.body instanceof FormData) &&
                null === options.headers.get('Content-Type')
            )
                options.headers.set('Content-Type', MIME_TYPE);


            return global.fetch(new URL('authentication_token', entryPoint), options).then(response => {
                if (response.ok) {
                    commit('updateIsLoading', false)
                    return response.json()
                }
                if (response.status === 401) {
                    throw new Error('Login error');
                }
                throw new Error('System error');
            }).then(data => {
                if (data && data.token !== undefined) {
                    commit('updateToken', data.token)
                    commit('updateUser', data.user)
                } else {
                    commit('setError', 'Erreur de login')
                }
            }).catch(error => {
                commit('setError', error)
            });
        },
        resetToken({commit}, isDeconnected = false) {
            commit('setError', 'Login timeout')
            commit('updateToken', null)
        }
    }
};
