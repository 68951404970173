<template>
    <v-card
            class="mx-auto"
            tile
            :max-height="height"
    >
        <v-speed-dial v-model="fab" top right direction="bottom" open-on-hover transition="scale" absolute class="pa-0 ma-0">
            <template v-slot:activator>
                <v-btn
                        v-model="fab"
                        color="blue darken-4"
                        dark
                        fab
                        small
                        class="pa-0 ma-0"
                >
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-cog</v-icon>
                </v-btn>
            </template>
<!--            <v-btn-->
<!--                    fab-->
<!--                    dark-->
<!--                    small-->
<!--                    color="green"-->
<!--            >-->
<!--                <v-icon>mdi-account-edit</v-icon>-->
<!--            </v-btn>-->
            <v-btn
                    fab
                    dark
                    small
                    color="red"
                    @click.prevent="logout"
            >
                <v-icon>mdi-logout-variant</v-icon>
            </v-btn>
        </v-speed-dial>
        <v-img
                :height="height"
                src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"
        >

            <v-row
                    align="end"
                    class="fill-height"
            >
                <v-col
                        align-self="start"
                        cols="12"
                >
                    <v-avatar
                            color="orange"
                    >
                        <span class="white--text headline">L</span>
                    </v-avatar>
                    <v-list-item
                            color="rgba(0, 0, 0, .4)"
                            dark
                    >
                        <v-list-item-content>
                            <v-list-item-title>{{user.fullname}}</v-list-item-title>
                            <v-list-item-subtitle><v-chip-group><v-chip v-for="role in user.roles">{{role}}</v-chip></v-chip-group></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-img>
    </v-card>
</template>

<script>
    import {mapFields} from "vuex-map-fields";
    import {mapActions} from "vuex";

    export default {
        name: "LoggedUser",
        props: {
            height: {
                type: String,
                default: "130px"
            }
        },
        data: () => ({
            fab: false,
        }),
        computed: {
            ...mapFields('userparameters', {
                token: 'token',
                showMenu: 'showMenu',
                user : 'user'
            }),
        },
        methods: {
            ...mapActions({
                logout: 'userparameters/resetToken'
            })
        }
    }
</script>

<style scoped>

</style>