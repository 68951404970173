export default {
  path: '/media_objects',
  name: 'media_objects',
  component: () => import('../components/mediaobject/Layout'),
  redirect: { name: 'MediaObjectsList' },
  children: [
    {
      name: 'MediaObjectList',
      path: '',
      component: () => import('../views/mediaobject/List')
    },
    {
      name: 'MediaObjectCreate',
      path: 'new',
      component: () => import('../views/mediaobject/Create')
    },
    {
      name: 'MediaObjectUpdate',
      path: ':id/edit',
      component: () => import('../views/mediaobject/Update')
    },
    {
      name: 'MediaObjectShow',
      path: ':id',
      component: () => import('../views/mediaobject/Show')
    }
  ]
};
