export default {
  path: '/animation_types',
  name: 'animation_types',
  component: () => import('../components/animationtype/Layout'),
  redirect: { name: 'AnimationTypeList' },
  children: [
    {
      name: 'AnimationTypeList',
      path: '',
      component: () => import('../views/animationtype/List')
    },
    {
      name: 'AnimationTypeCreate',
      path: 'new',
      component: () => import('../views/animationtype/Create')
    },
    {
      name: 'AnimationTypeUpdate',
      path: ':id/edit',
      component: () => import('../views/animationtype/Update')
    },
    {
      name: 'AnimationTypeShow',
      path: ':id',
      component: () => import('../views/animationtype/Show')
    }
  ]
};
