export default {
  path: '/sites',
  name: 'sites',
  component: () => import('../components/site/Layout'),
  redirect: { name: 'SiteList' },
  children: [
    {
      name: 'SiteList',
      path: '',
      component: () => import('../views/site/List')
    },
    {
      name: 'SiteCreate',
      path: 'new',
      component: () => import('../views/site/Create')
    },
    {
      name: 'SiteUpdate',
      path: ':id/edit',
      component: () => import('../views/site/Update')
    },
    {
      name: 'SiteShow',
      path: ':id',
      component: () => import('../views/site/Show')
    }
  ]
};
