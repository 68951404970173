export default {
  path: '/evenements',
  name: 'evenements',
  component: () => import('../components/evenement/Layout'),
  redirect: { name: 'EvenementList' },
  children: [
    {
      name: 'EvenementList',
      path: '',
      component: () => import('../views/evenement/List')
    },
    {
      name: 'EvenementCreate',
      path: 'new',
      component: () => import('../views/evenement/Create')
    },
    {
      name: 'EvenementUpdate',
      path: ':id/edit',
      component: () => import('../views/evenement/Update')
    },
    {
      name: 'EvenementShow',
      path: ':id',
      component: () => import('../views/evenement/Show')
    }
  ]
};
