import Vue from 'vue';
import VueRouter from 'vue-router';
import animationRoutes from './animation';
import animationCategorieRoutes from './animationcategorie';
import animationTypeRoutes from './animationtype';
import articleRoutes from './article';
import categoriePartenaireRoutes from './categoriepartenaire';
import enigmeRoutes from './enigme';
import evenementRoutes from './evenement';
import intervenantRoutes from './intervenant';
import intervenantTypeRoutes from './intervenanttype';
import localisationRoutes from './localisation';
import partenaireRoutes from './partenaire';
import siteRoutes from './site';
import mediaobjectRoutes from "./mediaobject";
import globalParams from './globalParams';
import Home from "../views/Home";

Vue.use(VueRouter);
const ROUTE_HOME = {
    path: '/',
    name: 'Home',
    component: Home
};
export default new VueRouter({
    mode: 'history',
    routes: [ROUTE_HOME,animationRoutes, animationCategorieRoutes, animationTypeRoutes, articleRoutes, categoriePartenaireRoutes,
        enigmeRoutes, evenementRoutes, intervenantRoutes, intervenantTypeRoutes, localisationRoutes, partenaireRoutes,
        siteRoutes, mediaobjectRoutes, globalParams]
});