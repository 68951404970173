export default {
  path: '/categorie_partenaires',
  name: 'categorie_partenaires',
  component: () => import('../components/categoriepartenaire/Layout'),
  redirect: { name: 'CategoriePartenaireList' },
  children: [
    {
      name: 'CategoriePartenaireList',
      path: '',
      component: () => import('../views/categoriepartenaire/List')
    },
    {
      name: 'CategoriePartenaireCreate',
      path: 'new',
      component: () => import('../views/categoriepartenaire/Create')
    },
    {
      name: 'CategoriePartenaireUpdate',
      path: ':id/edit',
      component: () => import('../views/categoriepartenaire/Update')
    },
    {
      name: 'CategoriePartenaireShow',
      path: ':id',
      component: () => import('../views/categoriepartenaire/Show')
    }
  ]
};
