<template>
  <v-row class="home" align="center" justify="center">
    <v-col cols="12" align-self="center" class="text-center">
    <img alt="Vue logo" src="../assets/image/ludinam_2019.png" style="max-width: 50vw" class="ma-auto">
    </v-col>
    <v-col cols="6" align-self="center">
      <v-card>
      <v-card-title class="text-center">
        Interface de gestion du festival Ludinam
      </v-card-title>
        <menu-admin/>
    </v-card>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import MenuAdmin from "../components/Menu";

export default {
  name: 'Home',
  components: {
    MenuAdmin,
    HelloWorld
  }
}
</script>
