export default {
  path: '/intervenant_types',
  name: 'intervenant_types',
  component: () => import('../components/intervenanttype/Layout'),
  redirect: { name: 'IntervenantTypeList' },
  children: [
    {
      name: 'IntervenantTypeList',
      path: '',
      component: () => import('../views/intervenanttype/List')
    },
    {
      name: 'IntervenantTypeCreate',
      path: 'new',
      component: () => import('../views/intervenanttype/Create')
    },
    {
      name: 'IntervenantTypeUpdate',
      path: ':id/edit',
      component: () => import('../views/intervenanttype/Update')
    },
    {
      name: 'IntervenantTypeShow',
      path: ':id',
      component: () => import('../views/intervenanttype/Show')
    }
  ]
};
